'use client';

import {useEffect, useRef, useState} from 'react';
import API from '../API';
import {AdFlags} from '../shared/bitsets';
import AdSense from 'react-adsense';

export default function Ad({orientation = 'square', page, position, context, housePlease = false}) {
    const [ad, setAd] = useState();
    const ref = useRef();

    useEffect(() => {
        async function fetchAd() {
            if (!ref.current) return; // wait for ad stub to exist so we can determine size

            // wait for adsense to load with a limit of 500ms
            for (let i = 0; i < 25; i++) {
                if (window.adngin && window.adngin.adnginLoaderReady) {
                    break;
                }
                await new Promise((resolve) => setTimeout(resolve, 20));
            }

            const container = {
                w: ref.current.offsetWidth,
                h: ref.current.offsetHeight,
                iv: isElementInViewport(ref.current)
            };

            try {
                const response = await API.get('/recommendation/ad', {
                    params: {
                        orientation,
                        page,
                        position,
                        context,
                        housePlease: housePlease ? 1 : 0,
                        bd: window.btoa(JSON.stringify(getBrowserData(container)))
                    }
                });
                if (response.status === 204) {
                    setAd(null);
                    return;
                }
                setAd(response.data);
            } catch (e) {
            }
        }

        fetchAd();
    }, [orientation, context, page, position, ref]);

    if (!ad) {
        if (orientation === 'square') {
            return <div ref={ref}
                        className={`${ad === null ? 'w-0 h-0' : 'w-250px h-250px'} m-2 transition-all duration-500 animate-pulse bg-gray-800 rounded`}/>;
        } else if (orientation === 'horizontal') {
            return <div ref={ref}
                        className={`w-full mt-3 mb-3 ${ad === null ? 'h-0' : 'h-100px'} transition-all duration-500 animate-pulse bg-gray-800 rounded`}/>;
        }

        throw new Error(`Invalid ad orientation '${orientation}'`);
    }

    switch (ad.type) {
        case 0:
            return <NativeAd ad={ad} orientation={orientation}/>;
        case 1:
            return <GoogleAd ad={ad} orientation={orientation}/>;
        case 2:
            return <MoneytizerAd ad={ad} orientation={orientation}/>;
        case 3:
            return <BillboardAd ad={ad} orientation={orientation}/>;
        case 4:
            return <SnigelAd ad={ad} orientation={orientation}/>;
        default:
            return null;
    }
}

function NativeAd({ad, orientation}) {
    let adImage = ad ? ((ad.flags & AdFlags.ExternalImage) !== 0 ? ad.asset : `https://cornhub.objectdepot.net/ad/${ad.asset}`) : null;

    if (orientation === 'square') {
        return <div
            className="w-250px h-250px m-2 aspect-video bg-gray-900 rounded bg-cover bg-no-repeat bg-center"
            style={{
                backgroundImage: `url(${adImage})`
            }}>
            <a href={ad.link} className="w-full h-full inline-block" target="_blank" rel="noreferrer"> </a>
        </div>;
    } else if (orientation === 'horizontal') {
        return <div
            className="w-full mt-3 mb-3 h-100px aspect-video bg-gray-900 rounded bg-cover bg-no-repeat bg-center"
            style={{
                backgroundImage: `url(${adImage})`
            }}>
            <a href={ad.link} className="w-full h-full inline-block" target="_blank" rel="noreferrer"> </a>
        </div>;
    }

    throw new Error(`Invalid ad orientation '${orientation}'`);
}

function GoogleAd({ad, orientation}) {
    if (orientation === 'square') {
        return <div
            className="w-250px h-250px m-2 aspect-video bg-gray-900 rounded">
            <AdSense.Google client={ad.client} slot={ad.slot} format="rectangle"/>
        </div>;
    } else if (orientation === 'horizontal') {
        return <div
            className="w-full h-100px mt-3 mb-3 transition-all duration-500 bg-gray-900 rounded">
            <AdSense.Google client={ad.client} slot={ad.slot} format="horizontal"/>
        </div>;
    }

    throw new Error(`Invalid ad orientation '${orientation}'`);
}

function MoneytizerAd({ad, orientation}) {
    if (!document.querySelector('#moneytizer-gen')) {
        addScript('https://ads.themoneytizer.com/s/gen.js?type=1', 'moneytizer-gen');
    }

    const ref = useRef();
    useEffect(() => {
        if (!ref.current) return;

        addScript(ad.scriptURL, `moneytizer-${ad.moneytizerId}`, ref.current);
    }, [ref]);

    if (orientation === 'square') {
        return <div
            className="w-250px h-250px m-2 aspect-video bg-gray-900 rounded">
            <div id={ad.moneytizerId} ref={ref}/>
        </div>;
    } else if (orientation === 'horizontal') {
        return <div
            className="w-full h-100px mt-3 mb-3 transition-all duration-500 bg-gray-900 rounded">
            <div id={ad.moneytizerId} ref={ref}/>
        </div>;
    }

    throw new Error(`Invalid ad orientation '${orientation}'`);
}

function BillboardAd({ad, orientation}) {
    const ref = useRef();

    useEffect(() => {
        window.billboardAds?.apply?.();
    }, [ref]);

    if (orientation === 'horizontal') {
        return <div className="w-full h-max mt-3 mb-3">
            <div
                className="w-full max-h-100px transition-all duration-500 bg-gray-900 rounded">
                <div
                    ref={ref}
                    className="max-h-100px"
                    data-billboardads-publisher="a0c96527-b144-45be-a135-2b71a8fe88b0"
                    data-billboardads-slot="43d5e536-57cd-4ef3-94d5-fa45888fe091"/>
            </div>
            <p className="text-center text-10px opacity-25 mt-5px">
                The product or service advertised above is not affiliated with CornHub
            </p>
        </div>;
    }
}

export function SnigelAd({ad, orientation}) {
    const ref = useRef();

    useEffect(() => {
        (async function() {
            // wait up to 500ms
            for (let i = 0; i < 5; i++) {
                if (window.adngin) break;
                await new Promise((resolve) => setTimeout(resolve, 100));
            }
            // give up?
            if (!window.adngin) return;

            window.adngin.queue.push(() => {
                window.adngin.cmd.startAuction([ad.adnginId]);
            });
        })();
    }, [ref]);

    if (orientation === 'square') {
        return <div
            className="w-250px min-h-250px m-2">
            <div id={`adngin-${ad.adnginId}-0`} ref={ref}/>
        </div>;
    } else if (orientation === 'horizontal') {
        return <div
            className="w-full min-h-80px mt-3 mb-3 flex justify-center">
            <div id={`adngin-${ad.adnginId}-0`} ref={ref}/>
        </div>;
    } else if (orientation === 'vertical') {
        return <div
            className="p-2 sticky left-0 top-0">
            <div id={`adngin-${ad.adnginId}-0`} ref={ref}/>
        </div>;
    }

    throw new Error(`Invalid ad orientation '${orientation}'`);
}

function addScript(url, id, parent = document.body) {
    const tag = document.createElement('script');
    tag.async = true;
    tag.id = id;
    tag.src = url;
    parent.appendChild(tag);
}

function getBrowserData(container) {
    return {
        dnt: navigator.doNotTrack,
        gpc: navigator.globalPrivacyControl,
        mtp: navigator.maxTouchPoints,
        ce: navigator.cookieEnabled,
        lang: navigator.language,
        langs: navigator.languages,
        ts: Date.now(),
        tzo: new Date().getTimezoneOffset(),
        iw: window.innerWidth,
        ih: window.innerHeight,
        o: window.screen.orientation ? window.screen.orientation.type : null,
        adsense: window.adsbygoogle !== undefined,
        billboard: window.billboardAds !== undefined,
        adngin: window.adngin !== undefined,
        moneytizer: document.querySelector('#moneytizer-gen') !== null,
        container
    };
}

function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
