import {Provider, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import API from '../API';
import NetworkBar from '../layout/NetworkBar';
import Header from '../layout/Header';
import {PolicyUpdatedModal} from '../modal/PolicyUpdatedModal';
import Footer from '../layout/Footer';
import 'windi.css';
import './_app.css';
import experiments from '../shared/experiments';
import {PRIVACY_REVISION, TERMS_REVISION} from '../shared/constants';
import Head from 'next/head';
import {getCookie, setCookie} from 'cookies-next';
import {Router, useRouter} from 'next/router';
import Loader from '../components/Loader';
import Script from 'next/script';
import * as gtag from '../util/gtag';
import {GA_MEASUREMENT_ID} from '../util/gtag';
import languages, {useI18NStringProvider} from '../i18n/i18n';
import {store} from '../store/configureStore';
import {SnigelAd} from '../layout/Ad';

export default function App({Component, pageProps}) {
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const i18n = useI18NStringProvider();

    useEffect(() => {
        gtag.pageview(router.asPath);

        const handleRouteChange = async (url) => {
            gtag.pageview(url);
            await fetch('https://tree-nation.com/track/web/6488c96491b2a');
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    Router.events.on('routeChangeStart', () => setLoading(true));
    Router.events.on('routeChangeComplete', () => setLoading(false));
    Router.events.on('routeChangeError', () => setLoading(false));

    useEffect(() => {
        loadExperimentOverrides();

        async function load() {
            if (router.query.authorization) {
                setCookie('authentication', router.query.authorization, {maxAge: 3600});
            }
            if (!getCookie('authentication')) {
                return store.dispatch({type: 'user:set', val: null});
            }

            try {
                const response = await API.get(`/user/@me`);
                store.dispatch({type: 'user:set', val: response.data});
            } catch (e) {
                store.dispatch({type: 'user:set', val: null});
            }
        }

        load();
    }, [router.query]);

    return <Provider store={store}>
        <Head>
            <title>CornHub</title>
            <meta name="description" content={i18n('meta.description')}/>
            {Object.keys(languages).filter((l) => l !== 'en' && l !== 'piglatin').map((lang) =>
                <link rel="alternate" hrefLang={lang} key={lang}
                      href={`https://${lang}.cornhub.website${router.asPath}`}/>)}
            <link rel="alternate" hrefLang="en" href={`https://cornhub.website${router.asPath}`}/>
            <link rel="alternate" hrefLang="x-default" href={`https://cornhub.website${router.asPath}`}/>
        </Head>
        <NetworkBar/>
        <Header/>
        <PolicyModal/>
        <div className="relative min-h-screen">
            <div className="absolute h-full left-0 ml-5 mt-[15px]">
                <SnigelAd ad={{adnginId: 'side_1'}} orientation="vertical"/>
            </div>
            <div className="absolute h-full right-0 mr-5 mt-[15px]">
                <SnigelAd ad={{adnginId: 'side_2'}} orientation="vertical"/>
            </div>
            <div className={loading ? 'hidden' : 'block'}>
                <Component {...pageProps} />
            </div>
        </div>
        {loading ? <Loader/> : null}
        <Footer/>
        <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-2Q4VQDHD0Y"></Script>
        <Script strategy="afterInteractive">
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_MEASUREMENT_ID}');`}
        </Script>
    </Provider>;
}

function PolicyModal() {
    const user = useSelector((state) => state.user);
    let policyChanges = user ? hasPolicyChanged(user.last_accepted_revisions) : null;

    return policyChanges && policyChanges.changed ?
        <PolicyUpdatedModal privacy={policyChanges.privacy} terms={policyChanges.terms} user={user}/> : null;
}

function hasPolicyChanged(lastAccepted) {
    let privacyV = (lastAccepted >> 8);
    let termsV = lastAccepted - (privacyV << 8);

    let privacy = PRIVACY_REVISION > privacyV;
    let terms = TERMS_REVISION > termsV;

    return {
        changed: privacy || terms,
        privacy,
        terms
    };
}

function loadExperimentOverrides() {
    const rawOverrides = getCookie('experiment_overrides');
    if (!rawOverrides) return;

    let overrides;
    try {
        overrides = JSON.parse(rawOverrides);
    } catch (e) {
        console.error('Failed to load experiment overrides', e);
        return;
    }

    for (const [key, value] of Object.entries(experiments)) {
        let override = overrides[key];
        if (override !== undefined && !value.possibleValues.includes(override)) {
            console.warn(`Illegal value ${String(JSON.stringify(override))} for experiment ${key}`);
            continue;
        }
        value.override = override;
    }
}
